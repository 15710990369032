<template>
    <div class="login-layout">

        <logo-with-girl/>
        <authorization-form
            v-model="model"
            ref="form"
            :passwordVisible="passwordVisible"
            :isLoading="isLoading"
            :lockTimes="lockTimes"
            :isShowInputError="isShowInputError"
            @toggle:passwordVisible="togglePasswordVisible"
            @on:submit="submit"
        />

    </div>
</template>

<script>
export default {
    emits: ["update:form", "on:submit", "toggle:passwordVisible"],
    model: {
        prop: "form",
        event: "update:form"
    },
    components: {
        AuthorizationForm: () => import("@/components/pages/Login/Form/AuthorizationForm.vue"),
        LogoWithGirl: () => import("@/components/pages/Login/Form/LogoWithGirl.vue")
    },
    props: {
        passwordVisible: Boolean,
        isLoading: Boolean,
        lockTimes: Number,
        isShowInputError: Boolean,
        form: {
            type: Object,
            required: true,
            default: () => ({
                username: "",
                phone: "",
                password: "",
            })
        }
    },
    computed: {
        model: {
            get() {
                return this.form
            },
            set($val) {
                this.$emit("update:form", $val)
            }
        }
    },
    methods: {
        togglePasswordVisible() {
            this.$emit("toggle:passwordVisible")
        },
        submit() {
            this.$emit("on:submit")
        }
    },
}
</script>
